















































































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import PillComponent from '@/components/pill/PillComponent.vue';
import CommunityUserInterest from '@/models/graphql/CommunityUserInterest';
import CommunityUserProfileRole from '@/models/graphql/CommunityUserProfileRole';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: { PillComponent },
  inheritAttrs: false,
})
export default class MemberAboutComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'MemberAboutWidgetStore';

  @Prop({ required: false, default: null })
  private memberCode!: string;

  @Prop({ required: false, default: () => [] })
  private roles!: CommunityUserProfileRole[];

  @Prop({ required: false, default: () => [] })
  private lookingToMeets!: CommunityUserProfileRole[];

  @Prop({ required: false, default: () => [] })
  private interests!: CommunityUserInterest[];

  @Prop({ required: false, default: () => [] })
  private sessionInterests!: CommunityUserInterest[];

  private get showComponent(): boolean {
    return (this.roles && this.roles.length > 0)
      || (this.lookingToMeets && this.lookingToMeets.length > 0)
      || (this.interests && this.interests.length > 0)
      || (this.sessionInterests && this.sessionInterests.length > 0);
  }

  created(): void {
    this.setDataConfig();
  }
}
